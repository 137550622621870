"use client";
import { getSortedBlogs } from "@/lib/mdxBlogs";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";

const truncateExcerpt = (excerpt) => {
  return (
    excerpt.split(" ").slice(0, 30).join(" ") +
    (excerpt.split(" ").length > 30 ? "..." : "")
  );
};

const FeaturedFirstRowPost = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const { blogs } = await getSortedBlogs(1, 1, "");
      console.log("Fetched blogs:", blogs);
      setBlogs(blogs);
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      {blogs.map((blog) => (
        <div
          key={blog.id}
          className="flex flex-col lg:flex-row items-center justify-center bg-white  overflow-hidden my-20 "
        >
          {/* Left section: Image */}
          <div className="relative w-full lg:w-2/3 overflow-hidden">
            <Link href={`/blog/${blog.slug}`}>
              <Image
                src={blog.featuredImage}
                alt={blog.title}
                className="w-full h-full object-cover transform transition duration-1000 ease-in-out hover:scale-110 "
                width={876}
                height={494}
                priority={true}
                sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
                layout="responsive"
              />
            </Link>
          </div>

          {/* Right section: Text */}
          <div className="px-8 flex flex-col lg:w-1/2">
            <div className="flex flex-row gap-2">
              {blog.category.map((cat, index) => (
                <span key={index}>
                  <Link
                    href={`/category/${cat}`}
                    className="text-colorLightBlue-500 hover:text-colorOrange-700 mr-2 py-2 text-lg"
                  >
                    <div
                      className="uppercase hover:underline font-bold text-color-brown-600 hover:text-color-peach-600"
                      color="info"
                    >
                      {cat}

                      {index < blog.category.length - 1 && (
                        <span className="mx-2">|</span>
                      )}
                    </div>
                  </Link>
                </span>
              ))}
            </div>
            <h3 className="text-4xl font-semibold my-1">
              <Link
                className="hover:text-colorOrange-700 text-colorBlueGray-800"
                href={`/blog/${blog.slug}`}
              >
                {blog.title}
              </Link>
            </h3>
            <div className="text-gray-600 my-1">
              {new Date(blog.date).toLocaleDateString()}
            </div>
            <div className="my-1 text-lg">{truncateExcerpt(blog.excerpt)}</div>+
            <Link
              className="inline-flex items-center bg-color-brown-400 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out mt-6 w-44"
              href={`/blog/${blog.slug}`}
            >
              <span className="mx-2">Read More</span>
              <span className="bg-color-brown-800 border-2 border-opacity-20 p-2 rounded-full">
                <FaArrowRight className="text-white w-4 h-4" />
              </span>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedFirstRowPost;
